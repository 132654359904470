import { CallToActionNames } from 'types/notification';
import { PendingOwner, UserInterface } from 'types/user';

export const newOwnerRequestCardDismissedKey = '_newOwnerRequestCardDismissed_';

export const displayNewOwnerMessage = (
  isSuccess: boolean,
  pendingOwners: PendingOwner[] | undefined,
  user: UserInterface,
  fetchTranslationMessage: (key: string) => string,
  setNewRequestMessage: (requestMessage: string) => void,
  setCardVisibility: (isVisible: boolean) => void,
) => {
  if (!isSuccess || !pendingOwners || pendingOwners.length === 0) return;

  const filteredPendingOwners = removeDismissedPendingOwners(user, pendingOwners);

  if (filteredPendingOwners.length === 0) return;

  const messageKey =
    filteredPendingOwners.length === 1 ? 'new-single-owner-request-text-body' : 'multiple-owner-request-text-body';

  const message = fetchTranslationMessage(messageKey);
  const email = filteredPendingOwners.length === 1 ? `${filteredPendingOwners[0].email} ` : '';

  setNewRequestMessage(`${email}${message}`);
  setCardVisibility(true);
};

export function fetchCacheStorageByKey(key: string) {
  return sessionStorage.getItem(key);
}

export function generateKey(user: UserInterface, pendingOwner: PendingOwner) {
  return `${user.activeCustomer?.idValue}${user.userEmail}${newOwnerRequestCardDismissedKey}${pendingOwner.accountId}`;
}

export const logCallToAction = (
  user: UserInterface,
  pendingOwners: PendingOwner[],
  callToAction: (ctaName: CallToActionNames) => Promise<void>,
) => {
  callToAction(CallToActionNames.DismissNewOwnerRequest).finally(() => {
    try {
      for (const owner of pendingOwners) {
        sessionStorage.setItem(generateKey(user, owner), owner.email);
      }
    } catch (error) {
      console.log(error);
    }
  });
};

function removeDismissedPendingOwners(user: UserInterface, pendingOwners: PendingOwner[]) {
  const filteredPendingOwners: PendingOwner[] = [];

  if (pendingOwners.length === 0) {
    return pendingOwners;
  }

  for (const owner of pendingOwners) {
    const previouslyDismissedRequest = fetchCacheStorageByKey(generateKey(user, owner));

    if (previouslyDismissedRequest == null || previouslyDismissedRequest !== owner.email) {
      filteredPendingOwners.push(owner);
    }
  }

  return filteredPendingOwners;
}
