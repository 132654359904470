import { useQuery } from 'react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { OpenBatchSummary } from 'types/reports';

const useOpenBatchAmountData = (enabled: boolean, callback?: (data: OpenBatchSummary) => unknown) => {
  const { data, isFetching, isError, isLoading, isSuccess, refetch, dataUpdatedAt } = useQuery<OpenBatchSummary>(
    [ReportsUrls.getOpenBatchSummary],
    async () => {
      return await reportService.GetOpenBatchSummary();
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      enabled: enabled,
      refetchInterval: 300000,
      onSuccess: (data) => {
        if (data != null && callback) callback(data);
        return data;
      },
    },
  );

  return { data, isFetching, isError, isSuccess, isLoading, refetch, dataUpdatedAt };
};

export default useOpenBatchAmountData;
