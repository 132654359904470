import { VegaBox, VegaButtonLink, VegaCard, VegaFlex, VegaFont } from '@heartlandone/vega-react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';

import { DeviceIsMobile } from 'utility/showOnDevice';

import styles from './productsCard.module.css';

export type ProductsCardProps = {
  id: string;
  title: string;
  description: string;
  icon: string;
  altIcon?: string | null;
  link: string;
};

const ProductsCard = ({ title, description, icon, altIcon, link }: ProductsCardProps) => {
  const isMobile = DeviceIsMobile();
  const { t } = useTranslation('discover');

  const iconUrl = `${process.env.NEXT_PUBLIC_IMAGE_URL}/myAccount/discovery/${icon}`;
  const altIconUrl = `${process.env.NEXT_PUBLIC_IMAGE_URL}/myAccount/discovery/${altIcon}`;
  const router = useRouter();

  return (
    <VegaCard className={styles.productsCard} data-testid={title} padding={'size-24'}>
      <VegaFlex direction={'col'}>
        <VegaBox>
          <VegaFlex direction={isMobile ? 'col' : 'row'} alignItems={isMobile ? 'center' : 'start'} gap={'size-16'}>
            <VegaBox style={{ display: 'inline-flex', flexDirection: 'row' }}>
              <div className={isMobile ? styles.rectangle : ''}>
                <picture>
                  <img src={` ${iconUrl}`} height={'136px'} width={'116px'} alt={altIconUrl} />
                </picture>
              </div>
            </VegaBox>
            <VegaBox style={{ width: isMobile ? '100%' : 'auto', height: '100%' }}>
              <VegaFlex direction={`col`} gap={'size-16'}>
                <VegaBox>
                  <VegaFont className={styles.title} variant={'font-h6'}>
                    {t(title)}
                  </VegaFont>
                </VegaBox>
                <VegaBox>
                  <VegaFont className={styles.description} color={`text-secondary`} as={'span'}>
                    {t(description)}
                  </VegaFont>
                </VegaBox>
              </VegaFlex>
            </VegaBox>
          </VegaFlex>
        </VegaBox>
        <VegaBox margin={'size-16'}>
          <VegaButtonLink
            className={styles.link}
            label={t('gettingStartedLabel')}
            icon="fa fa-arrow-right-long"
            iconAlign="right"
            onVegaClick={() => router.push(link)}
            data-testid="getStartedButton"
          />
        </VegaBox>
      </VegaFlex>
    </VegaCard>
  );
};

export default ProductsCard;
