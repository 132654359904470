import { ActionType, SupportItem } from 'types/supportItem';

export const helpCenterItem: SupportItem = {
  name: 'Help Center',
  iconPath: 'myAccount/HelpCenter.svg',
  actionName: 'Help Center',
  actionUrl: 'http://Support.HeartlandHelpCenter.com',
  isContactUsOption: true,
  actionType: ActionType.url,
};
const chatItem: SupportItem = {
  name: 'Chat With Us',
  iconPath: 'myAccount/ChatWithUs.svg',
  actionName: 'Chat with our Support Team',
  isContactUsOption: true,
  actionType: ActionType.chat,
};
const phoneItem: SupportItem = {
  name: 'Give us a Call',
  iconPath: 'myAccount/GiveUsACall.svg',
  phoneNumber: '(800) 285-0005',
  actionName: '(800) 285-0005',
  isContactUsOption: true,
  actionType: ActionType.phone,
};

const supportItems: SupportItem[] = [helpCenterItem, chatItem, phoneItem];

export default supportItems;
