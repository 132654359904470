export const colorAccentPrimaryStroke = '#00BBFF';
export const colorAccentPrimaryFill = '#E3F7FD';

export const colorAccentSecondaryStroke = '#FFBF3F';
export const colorAccentSecondaryFill = '#FFF7E3';

export const typeColors = ['#FFDC83', '#73E6DC', '#FF82C9', '#806BFF', '#00BBFF', '#FF9571'];

export const emptyColors = ['#FFF7E3', '#FFEFC8', '#FFDC83'];

export const emptyChartContainerId = 'emptyChartContainer';

export const unselectedSliceIndex = -1;
