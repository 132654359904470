import { SalesRepresentative } from 'types/reports';

export function getNameLabel(
  data: SalesRepresentative | undefined,
  isError: boolean,
  isFetching: boolean,
  t: (key: string) => string,
): string {
  if (isError) {
    return t('error');
  }

  if (isFetching) {
    return '--';
  }

  if (data?.firstName) {
    return `${data.firstName} ${data.lastName}`;
  }

  return t('noRep');
}
