import React from 'react';

import { VegaBox, VegaCard, VegaFlex, VegaFont, VegaIcon } from '@heartlandone/vega-react';
import { parseISO } from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import { useTranslation } from 'next-i18next';

import useLastDepositData from 'hooks/reports/useLastDepositData';
import { DashboardComponentProps } from 'types/dashboard';
import { numberWithCommas } from 'utility/helper/helperFunctions';

import styles from './index.module.css';

const LastDeposit: React.FC<DashboardComponentProps> = () => {
  const { t } = useTranslation('reports');

  const { data, isLoading } = useLastDepositData();

  const amount = data?.amount == null ? '--' : numberWithCommas(data?.amount);

  const date = data?.date ? dateFnsTz.formatInTimeZone(parseISO(data?.date), 'UTC', 'MM/dd/yy') : '--';

  return (
    <VegaCard padding={'size-24'} className={styles.grossSales}>
      <VegaFlex
        direction={{ XL: 'row', L: 'col', M: 'col', S: 'col' }}
        gap={'size-32'}
        style={{ height: '100%', width: '100%' }}
        justifyContent={'space-between'}
      >
        <VegaFlex direction={'col'} justifyContent={'start'} alignItems={'start'} gap={'size-16'}>
          <VegaFont variant={'font-field-value'} color="text-secondary">
            {t('lastDeposit')}
          </VegaFont>
          <VegaFont variant={amount.length > 10 ? 'font-h4' : 'font-h3'}>
            <b>$ {isLoading ? '--' : amount}</b>
          </VegaFont>
          {!isLoading && date && (
            <VegaBox>
              <VegaIcon size="size-16" icon="fa-thin fa-clock-nine" style={{ marginRight: '4px' }} />
              <VegaFont variant={'font-field-value-sm'} color="text-secondary" style={{ marginRight: '8px' }}>
                {t('lastDepositDate')}{' '}
                <VegaFont color="text-primary" variant={'font-field-value-sm'}>
                  <b>{date}</b>
                </VegaFont>
              </VegaFont>
            </VegaBox>
          )}
          {isLoading && (
            <VegaFont variant={'font-field-value-sm'} color="text-secondary">
              {t('loading')}
            </VegaFont>
          )}
        </VegaFlex>
      </VegaFlex>
    </VegaCard>
  );
};

export default LastDeposit;
