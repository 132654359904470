import { useQuery } from 'react-query';

import reportService, { ReportsUrls } from 'api/services/report';
import { SalesRepresentative } from 'types/reports';

const useSalesRepresentativeData = (callback?: (data: SalesRepresentative) => unknown, isEnabled = true) => {
  const { data, isFetching, isError, isSuccess } = useQuery<SalesRepresentative>(
    [ReportsUrls.getSalesRepresentative],
    async () => {
      return await reportService.GetSalesRepresentative();
    },
    {
      onSuccess: (data) => {
        if (data != null) {
          if (callback) callback(data);
          return data;
        }
      },
      refetchOnWindowFocus: false,
      enabled: isEnabled,
    },
  );

  return { data, isFetching, isError, isSuccess };
};

export default useSalesRepresentativeData;
