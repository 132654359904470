import { VegaFlex, VegaFont, VegaIcon } from '@heartlandone/vega-react';
import { useTranslation } from 'next-i18next';

import { numberWithCommas } from 'utility/helper/helperFunctions';

const TrendIndicator = ({ trend }: { trend?: number }) => {
  const { t } = useTranslation('reports');
  if (trend == null) {
    return (
      <VegaFlex direction={'row'}>
        <VegaFont variant={'font-field-value-sm'} color="text-secondary">
          {t('trendDataNotAvailable')}
        </VegaFont>
      </VegaFlex>
    );
  }

  return (
    <VegaFlex direction={'row'} gap={'size-8'} justifyContent={'center'} alignItems={'center'}>
      {trend > 0 && (
        <VegaIcon color={'text-success'} size="size-16" data-testid="trend-angles-up" icon="fas fa-angles-up" />
      )}
      {trend < 0 && (
        <VegaIcon color="text-error" size="size-16" data-testid="trend-angles-down" icon="fas fa-angles-down" />
      )}
      <div>
        <VegaFont variant={'font-field-value-sm'}>
          <b>{numberWithCommas(Math.abs(trend))}%</b>
        </VegaFont>
        <VegaFont variant={'font-field-value-sm'} color="text-secondary">
          &nbsp;{t('fromPreviousPeriod')}
        </VegaFont>
      </div>
    </VegaFlex>
  );
};

export default TrendIndicator;
