import { useState } from 'react';
import React from 'react';

import { DatePickerModeType, DatePickerValueType } from '@heartlandone/vega';
import {
  VegaBox,
  VegaCard,
  VegaDatePicker,
  VegaFlex,
  VegaFont,
  VegaInputSelect,
  VegaLoadingIndicator,
} from '@heartlandone/vega-react';
import format from 'date-fns/format';
import { useTranslation } from 'next-i18next';

import usePaymentTypeData from 'hooks/reports/usePaymentTypeData';
import { DashboardComponent, DashboardComponentProps } from 'types/dashboard';
import { BatchRange, DashboardComponentOptions } from 'types/reports';
import { formattedMaxReportDate } from 'utility/helper/helperFunctions';

import PaymentTypeDonut, { EmptyDonutChart } from './chart/paymentTypeDonut';

import styles from './index.module.css';

const PaymentTypeChart: React.FC<DashboardComponentProps> = ({ initialConfigurationJSON, saveCustomization }) => {
  const { t } = useTranslation('reports');
  const parsedConfiguration = JSON.parse(initialConfigurationJSON);

  const rangePlaceHolderLabels = [t('dateRange.startDate'), t('dateRange.endDate')];

  const [queryRange, setQueryRange] = useState<BatchRange>(parsedConfiguration.range);
  const [localRange, setLocalRange] = useState<BatchRange>(parsedConfiguration.range);

  const [customRange, setCustomRange] = useState<Array<string | null>>([
    parsedConfiguration.minDate,
    parsedConfiguration.maxDate,
  ]);

  const { data, isLoading, isError } = usePaymentTypeData(
    queryRange,
    customRange[0] ? format(new Date(customRange[0]), 'yyyy-MM-dd') : undefined,
    customRange[1] ? format(new Date(customRange[1]), 'yyyy-MM-dd') : undefined,
  );

  const NotLoadingNotError = !isLoading && !isError;

  return (
    <VegaCard padding={'size-24'} className={styles.paymentTypeChartContainer}>
      <VegaFlex
        className={styles.header}
        direction={{ XL: 'row', L: 'row', M: 'row', S: 'col' }}
        gap={'size-32'}
        style={{ width: '100%' }}
        justifyContent={{ XL: 'space-between', L: 'space-between', M: 'space-between', S: 'start' }}
        alignItems={{ XL: 'center', L: 'center', M: 'center', S: 'start' }}
      >
        <VegaFlex direction={'col'} justifyContent={'start'} alignItems={'start'} gap={'size-16'}>
          <VegaFont variant={'font-h5'}>{t('paymentMethod')}</VegaFont>
        </VegaFlex>
        <div className={styles.selectContainer}>
          <VegaFlex direction={'col'} gap={'size-16'} style={{ width: '100%' }}>
            <VegaInputSelect
              size={'small'}
              style={{ width: '100%' }}
              value={localRange}
              data-testid="payment-type-report-range-selector"
              onVegaChange={(e) => {
                const targetValue = e.target.value as BatchRange;

                if (targetValue !== BatchRange.Custom) {
                  saveCustomization(DashboardComponent.PaymentTypeChart, JSON.stringify({ range: targetValue }));
                  setCustomRange([null, null]);
                  setQueryRange(targetValue);
                }

                setLocalRange(targetValue);
              }}
              className={styles.select}
              source={DashboardComponentOptions.map((x) => ({
                displayName: t('dateRange.' + x.displayName),
                id: x.id,
              }))}
            />
            {localRange == BatchRange.Custom && (
              <React.Fragment>
                <VegaFlex direction={'row'} alignItems={'center'} gap="size-12">
                  <VegaDatePicker
                    mode="range"
                    data-testid="payment-type-report-date-selector"
                    size="small"
                    value={customRange as DatePickerValueType<DatePickerModeType> | undefined}
                    placeholder={rangePlaceHolderLabels}
                    maxDate={formattedMaxReportDate()}
                    onVegaChange={(e: any) => {
                      if (e.target.value[0] == customRange[0] && e.target.value[1] == customRange[1]) return;
                      if (e.target.value[0] == null || e.target.value[1] == null) return;
                      if (e.target.value[0] !== '' && e.target.value[1] !== '') {
                        setQueryRange(BatchRange.Custom);
                        setCustomRange(e.target.value);

                        saveCustomization(
                          DashboardComponent.PaymentTypeChart,
                          JSON.stringify({
                            range: BatchRange.Custom,
                            minDate: e.target.value[0],
                            maxDate: e.target.value[1],
                          }),
                        );
                      }
                    }}
                    clearButton={true}
                  />
                </VegaFlex>
              </React.Fragment>
            )}
          </VegaFlex>
        </div>
      </VegaFlex>
      <VegaBox margin={{ top: 'size-24' }}>
        {isLoading && (
          <VegaFlex justifyContent={'center'}>
            <VegaLoadingIndicator data-testid="payment-type-chart-loading" />
          </VegaFlex>
        )}
        {isError && <VegaFont>{t('errorMessages.errorFetching')}</VegaFont>}
        {NotLoadingNotError && data!.length === 0 && <EmptyDonutChart />}
        {NotLoadingNotError && data!.length > 0 && <PaymentTypeDonut data={data!} />}
      </VegaBox>
    </VegaCard>
  );
};

export default PaymentTypeChart;
