import { routes } from 'types/pages';

export const discoveryProductsList = [
  {
    id: '1',
    title: 'productCards.paymentsAnywhereAnytime.title',
    description: 'productCards.paymentsAnywhereAnytime.description',
    icon: 'PaymentAnywhereAnytime.png',
    altIcon: 'PaymentAnywhereAnytime.png',
    link: routes.inPerson,
  },
  {
    id: '2',
    title: 'productCards.allInOnePayrollHrSoftware.title',
    description: 'productCards.allInOnePayrollHrSoftware.description',
    icon: 'PayrollHrSoftware.png',
    altIcon: 'PayrollHrSoftware.png',
    link: routes.payrollPlus,
  },
  {
    id: '3',
    title: 'productCards.fastAndSimpleRestaurantManagement.title',
    description: 'productCards.fastAndSimpleRestaurantManagement.description',
    icon: 'TransformServiceHandHeldPos.png',
    altIcon: 'TransformServiceHandHeldPos.png',
    link: routes.restaurant,
  },
  {
    id: '4',
    title: 'productCards.smarterRetailPOSTechnology.title',
    description: 'productCards.smarterRetailPOSTechnology.description',
    icon: 'FastEastRestaurantPos.png',
    altIcon: 'FastEastRestaurantPos.png',
    link: routes.retail,
  },
];
