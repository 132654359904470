import { useState } from 'react';
import React from 'react';

import { DatePickerModeType, DatePickerValueType } from '@heartlandone/vega';
import { VegaCard, VegaDatePicker, VegaFlex, VegaFont, VegaInputSelect } from '@heartlandone/vega-react';
import format from 'date-fns/format';
import { useTranslation } from 'next-i18next';

import useGrossSalesData from 'hooks/reports/useGrossSalesData';
import { DashboardComponent, DashboardComponentProps } from 'types/dashboard';
import { BatchRange, DashboardComponentOptions } from 'types/reports';
import { formattedMaxReportDate, numberWithCommas } from 'utility/helper/helperFunctions';

import TrendIndicator from './trendIndicator';

import styles from './index.module.css';

const GrossSales: React.FC<DashboardComponentProps> = ({ initialConfigurationJSON, saveCustomization }) => {
  const { t } = useTranslation('reports');

  const rangePlaceHolderLabels = [t('dateRange.startDate'), t('dateRange.endDate')];

  const parsedConfiguration = JSON.parse(initialConfigurationJSON);
  const [queryRange, setQueryRange] = useState<BatchRange>(parsedConfiguration.range);
  const [localRange, setLocalRange] = useState<BatchRange>(parsedConfiguration.range);

  const [customRange, setCustomRange] = useState<Array<string | null>>([
    parsedConfiguration.minDate,
    parsedConfiguration.maxDate,
  ]);

  const { data, isLoading } = useGrossSalesData(
    queryRange,
    customRange[0] ? format(new Date(customRange[0]), 'yyyy-MM-dd') : undefined,
    customRange[1] ? format(new Date(customRange[1]), 'yyyy-MM-dd') : undefined,
  );

  const grossSalesTotal = data?.grossSales == null ? '--' : numberWithCommas(data?.grossSales);
  const trend = data?.trend;

  return (
    <VegaCard padding={'size-24'} className={styles.grossSales}>
      <VegaFlex
        direction={{ XL: 'row', L: 'col', M: 'col', S: 'col' }}
        gap={'size-32'}
        style={{ height: '100%', width: '100%' }}
        justifyContent={'space-between'}
      >
        <VegaFlex direction={'col'} justifyContent={'start'} alignItems={'start'} gap={'size-16'}>
          <VegaFont variant={'font-field-value'} color="text-secondary">
            {t('grossSales')}
          </VegaFont>
          <VegaFont variant={grossSalesTotal.length > 10 ? 'font-h4' : 'font-h3'}>
            <b>$ {isLoading ? '--' : grossSalesTotal}</b>
          </VegaFont>
          {queryRange !== BatchRange.Custom && !isLoading && <TrendIndicator trend={trend} />}
          {isLoading && (
            <VegaFont variant={'font-field-value-sm'} color="text-secondary">
              {t('loading')}
            </VegaFont>
          )}
        </VegaFlex>
        <VegaFlex direction={'col'} gap={'size-16'}>
          <VegaInputSelect
            size={'small'}
            value={localRange}
            data-testid="gross-sales-report-range-selector"
            onVegaChange={(e) => {
              const targetValue = e.target.value as BatchRange;

              if (targetValue !== BatchRange.Custom) {
                saveCustomization(DashboardComponent.GrossSales, JSON.stringify({ range: targetValue }));
                setCustomRange([null, null]);
                setQueryRange(targetValue);
              }

              setLocalRange(targetValue);
            }}
            className={styles.select}
            source={DashboardComponentOptions.map((x) => ({ displayName: t('dateRange.' + x.displayName), id: x.id }))}
          />
          {localRange == BatchRange.Custom && (
            <React.Fragment>
              <VegaFlex direction={'row'} alignItems={'center'} gap="size-12" style={{ maxWidth: 285 }}>
                <VegaDatePicker
                  mode="range"
                  data-testid="gross-sales-report-date-selector"
                  size="small"
                  value={customRange as DatePickerValueType<DatePickerModeType> | undefined}
                  placeholder={rangePlaceHolderLabels}
                  maxDate={formattedMaxReportDate()}
                  onVegaChange={(e: any) => {
                    if (e.target.value[0] == customRange[0] && e.target.value[1] == customRange[1]) return;
                    if (e.target.value[0] == null || e.target.value[1] == null) return;
                    if (e.target.value[0] !== '' && e.target.value[1] !== '') {
                      setQueryRange(BatchRange.Custom);
                      setCustomRange(e.target.value);

                      saveCustomization(
                        DashboardComponent.GrossSales,
                        JSON.stringify({
                          range: BatchRange.Custom,
                          minDate: e.target.value[0],
                          maxDate: e.target.value[1],
                        }),
                      );
                    }
                  }}
                  clearButton={true}
                />
              </VegaFlex>
            </React.Fragment>
          )}
        </VegaFlex>
      </VegaFlex>
    </VegaCard>
  );
};

export default GrossSales;
