import { CustomDashboard, CustomizeDashboardParam } from 'types/dashboard';

import apiFunctions from '../..';

const DashboardControllerBasePath = '/api/v1/DashboardComponent/';

export const DashboardControllerUrls = {
  GetCustomDashboardRoute: DashboardControllerBasePath + 'GetCustomDashboard',
  CustomizeDashboardRoute: DashboardControllerBasePath + 'CustomizeDashboard',
};

export async function GetCustomDashboard(): Promise<CustomDashboard> {
  return await apiFunctions.get(DashboardControllerUrls.GetCustomDashboardRoute);
}

export async function CustomizeDashboard(param: CustomizeDashboardParam): Promise<void> {
  return await apiFunctions.post(DashboardControllerUrls.CustomizeDashboardRoute, param);
}
