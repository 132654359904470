import { VegaBox, VegaFont } from '@heartlandone/vega-react';

import { typeColors } from '../chart.constants';

import styles from '../chart.module.css';

const CustomTooltip = (props: any) => {
  const displayValue: string = props.payload?.[0]?.payload.paymentType;
  const displayValue2: string = props.payload?.[0]?.payload.paymentTypeTotalAmountFormatted;
  const displayValue3: string = props.payload?.[0]?.payload.paymentTypePercentage;
  const displayIndex: number = props.payload?.[0]?.payload.index;

  return (
    <VegaBox className={styles.tooltip} backgroundColor={'bg-inverted-primary'} corners={'rounded-12'}>
      <div
        style={{
          backgroundColor: typeColors[displayIndex],
          width: '15px',
          height: '15px',
          marginRight: '10px',
          display: 'inline',
          borderRadius: '50%',
        }}
      ></div>
      <div style={{ textAlign: 'left' }}>
        <VegaFont color="text-inverted-primary" data-testid="tooltip-title">
          <b>{displayValue}</b>
        </VegaFont>
        <br />
        <VegaFont color="text-inverted-primary" data-testid="tooltip-percentage">
          {displayValue3}
        </VegaFont>
        <br />
        <VegaFont color="text-inverted-primary" data-testid="tooltip-value">
          {displayValue2}
        </VegaFont>
      </div>
    </VegaBox>
  );
};

export default CustomTooltip;
