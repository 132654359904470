import React from 'react';

import { VegaBox, VegaButton, VegaButtonLink, VegaFlex, VegaFont, VegaGrid } from '@heartlandone/vega-react';
import router from 'next/router';
import { Trans, useTranslation } from 'next-i18next';

import LayoutCard from 'components/layout/layoutCard';
import { LayoutTypes } from 'types/app';

import styles from './membersCard.module.css';

interface Props {
  isVisible: boolean;
  onDismiss: () => void;
  onAddTeamMember: () => void;
}

const MembersCard: React.FC<Props> = ({ isVisible, onDismiss, onAddTeamMember }) => {
  const { t } = useTranslation('members-card');

  if (!isVisible) {
    return null;
  }

  return (
    <LayoutCard type={LayoutTypes.Card}>
      <VegaBox data-testid="add-members-card">
        <VegaGrid column={{ default: 4, S: 1, M: 4, L: 4 }} gap={'size-24'}>
          <VegaBox className={styles.cardText}>
            <VegaFont variant="font-h5">{t('add-team-member')}</VegaFont>
            <p>
              <VegaFont variant="font-p2-long" color="text-secondary">
                <Trans
                  i18nKey="text-body"
                  t={t}
                  components={{
                    userButton: <VegaButtonLink label={t('users')} onVegaClick={() => router.push('/users')} />,
                  }}
                />
              </VegaFont>
            </p>
          </VegaBox>
          <VegaFlex className={styles.CTAbuttons} justifyContent="center" alignItems="center" direction="col">
            <VegaButton
              label={t('add-team-member')}
              block={true}
              style={{ marginBottom: '16px', width: '100%' }}
              onVegaClick={onAddTeamMember}
              data-testid="addTeamMember"
            ></VegaButton>
            <VegaButton
              label={t('dismiss')}
              variant="secondary"
              block={true}
              style={{ width: '100%' }}
              onVegaClick={onDismiss}
            ></VegaButton>
          </VegaFlex>
        </VegaGrid>
      </VegaBox>
    </LayoutCard>
  );
};

export default MembersCard;
